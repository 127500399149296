import React from "react";
import ProductBanner from "../../components/global/product/ProductBanner";
import ProductMain from "../../components/global/product/ProductMain";
import ProductBlack from "../../components/global/product/ProductBlack";
import ProductEasyAccess from "../../components/global/product/ProductEasyaccess";
import ProductBlackSteps from "../../components/global/product/ProductBlackSteps";
import Factsheet from "../../components/global/Factsheet";
import GetInTouch from "../../components/global/forms/GetInTouch";
import Faqs from "../../components/global/Faqs";
import Testimonials from "../../components/global/Testimonials";
import ProductGreenSteps from "../../components/global/product/ProductGreenSteps";

const ProductPagePreview = ({ entry, fieldsMetaData }) => {
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
      <>
        <ProductBanner {...data?.productBanner} />
        <ProductMain {...data?.productMain} />
        <ProductBlack {...data?.productBlack} />
        <ProductEasyAccess {...data?.productEasyAccess} />
        {data?.productGreenSteps != null && <ProductGreenSteps {...data?.productGreenSteps}/>}
        <ProductBlackSteps {...data?.productBlackSteps} />
        <Factsheet {...data?.factSheet} isPreview />
        <Testimonials {...data?.testimonials} />
        <GetInTouch {...data?.getInTouch} isPreview />
        <Faqs {...data?.faqs} />
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default ProductPagePreview;
